@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@font-face {
  font-family: "SpaceFont";
  src: url("/fonts/MonsterBites.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SpaceFont";
  src: url("/fonts/MonsterBitesItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/*body {*/
/*  color: rgb(var(--foreground-rgb));*/
/*  background: linear-gradient(*/
/*      to bottom,*/
/*      transparent,*/
/*      rgb(var(--background-end-rgb))*/
/*    )*/
/*    rgb(var(--background-start-rgb));*/
/*}*/

/*@layer utilities {*/
/*  .text-balance {*/
/*    text-wrap: balance;*/
/*  }*/
/*}*/

/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  color: rgba(255, 255, 255, 0.87);*/
/*  background-color: #000000;*/
/*  font-family: Arial, Helvetica, sans-serif;*/
/*}*/

.hide-scrollbar-track::-webkit-scrollbar {
  width: 8px;
}

.hide-scrollbar-track::-webkit-scrollbar-track {
  background-color: inherit;
}

.hide-scrollbar-track::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}

.font-space {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
